import React from 'react';
import { Image } from 'nordic/image';
import { Text } from '@andes/typography';

const HomeTuMoto = (
  <section className="col-50 section section-center--card">
    <div className="card text-align-center">
      <div className="card-content">
        <div className="title">
          <Text size="l" color="secondary">
            Bienvenido a
          </Text>
        </div>
        <div className="logo">
          <Image
            lazyload={false}
            src="homes/logo-tumoto@2x.png"
            alt="www.tumoto.com.co"
            className="logo-img"
          />
        </div>
        <div className="subtitle">
          <span>Elige tu país</span>
        </div>
      </div>
      <div className="card-footer">
        <div className="action-link">
          <Text
            className="flag flag-colombia"
            component="a"
            color="primary"
            href="https://www.tumoto.com.co/"
          >
            Colombia
          </Text>
        </div>
        <div className="action-link">
          <Text
            className="flag flag-venezuela"
            component="a"
            color="primary"
            href="https://www.tumoto.com.ve/"
          >
            Venezuela
          </Text>
        </div>
      </div>
    </div>
  </section>
);

export { HomeTuMoto };
