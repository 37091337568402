import { HomeTuCarro } from './sites/tc';
import { HomeTuMoto } from './sites/tm';
import { HomeTuInmueble } from './sites/ti';

const tuxContent = {
  TC: HomeTuCarro,
  TM: HomeTuMoto,
  TI: HomeTuInmueble,
};

export { tuxContent };
