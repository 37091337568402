import type { FC } from 'react';

import React from 'react';
import { AndesProvider } from '@andes/context';
import { Locale } from 'nordic/i18n';
import { tuxContent } from '../../pages/homes-tux/content';

const getPageContent = (platformId: string): React.ReactElement =>
  tuxContent[platformId];

interface PagePros {
  device: Record<string, unknown>;
  platformId: string;
  locale: Locale;
}

const Page: FC<PagePros> = ({ device, platformId, locale }) => (
  <AndesProvider locale={locale} device={device}>
    {getPageContent(platformId)}
  </AndesProvider>
);

export const hydrate = 'hydrateRoot';

export const getServerSideProps = async ({ device, platform }) => ({
  props: {
    device: device,
    platformId: platform.id,
    locale: platform.locale,
  },
  settings: {
    title: platform.title,
  },
});

export const setPageSettings = ({ settings }) => ({
  title: settings.title,
  className: 'homes-tux',
  layout: {
    staticMarkup: false,
  },
  navigation: {
    type: 'hidden',
  },
});

export default Page;
