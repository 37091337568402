import React from 'react';
import { Image } from 'nordic/image';
import { Text } from '@andes/typography';

const HomeTuCarro = (
  <section className="col-50 section section-center--card">
    <div className="card text-align-center">
      <div className="card-content">
        <div className="title">
          <Text component="p" size="l" color="secondary">
            Bienvenido a
          </Text>
        </div>
        <div className="logo">
          <Image src="homes/logo-tucarro@2x.png" alt="www.tucarro.com.co" className="logo-img" />
        </div>
        <div className="subtitle">
          <span>Elige tu país</span>
        </div>
      </div>
      <div className="card-footer">
        <div className="action-link">
          <Text
            className="flag flag-colombia"
            component="a"
            color="primary"
            href="https://www.tucarro.com.co/"
          >
            Colombia
          </Text>
        </div>
        <div className="action-link">
          <Text
            className="flag flag-venezuela"
            component="a"
            color="primary"
            href="https://www.tucarro.com.ve/"
          >
            Venezuela
          </Text>
        </div>
      </div>
    </div>
  </section>
);

export { HomeTuCarro };
