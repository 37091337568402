import React from 'react';
import { Image } from 'nordic/image';
import { Text } from '@andes/typography';

const HomeTuInmueble = (
  <>
    <section className="col-50 palette-gray--main section section-center--card section-info--card">
      <div className="card text-align-center">
        <div className="card-header">
          <Image src="homes/logo-mercadolibre@2x.png" alt="Mercado Libre" className="logo-img" />
        </div>
        <div className="card-content">
          <Text component="p" size="m" color="secondary">
            En Colombia y Panamá,
          </Text>
          <Text component="p" size="m" color="secondary">
            TuInmueble ahora es MercadoLibre.
          </Text>
          <Text component="p" size="m" color="secondary">
            ¡Descúbrelo!
          </Text>
        </div>
        <div className="card-footer">
          <div className="action-link">
            <Text
              data-testid="link-colombia"
              className="flag flag-colombia"
              component="a"
              color="secondary"
              href="http://www.mercadolibre.com.co/inmuebles/"
            >
              Ir a MercadoLibre Colombia
            </Text>
          </div>
          <div className="action-link">
            <Text
              data-testid="link-panama"
              className="flag flag-panama"
              component="a"
              color="secondary"
              href="http://inmuebles.mercadolibre.com.pa/"
            >
              Ir a MercadoLibre Panamá
            </Text>
          </div>
        </div>
      </div>
    </section>
    <section className="col-50 palette-white--main section section-center--card section-info--card">
      <div className="card text-align-center">
        <div className="card-header">
          <Image src="homes/logo-tuinmueble@2x.png" alt="Tuinmueble" className="logo-img" />
        </div>
        <div className="card-content">
          <Text component="p" size="m" color="secondary">
            ¿Estás en Venezuela?
          </Text>
        </div>
        <div className="card-footer">
          <Text
            data-testid="link-venezuela"
            className="flag flag-venezuela"
            component="a"
            color="secondary"
            href="http://www.tuinmueble.com.ve/"
          >
            Ir a TuInmueble Venezuela
          </Text>
        </div>
      </div>
    </section>
  </>
);

export { HomeTuInmueble };
